const MuiHashLink = {
  styleOverrides: {
    root: {
      textDecoration: 'none',
      color: 'red',
    },
  },
};

export default MuiHashLink;
